import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import styles from './ResumeSimulation.module.scss';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../../../../components/NavBar';
import logo from '../../../../assets/images/acelera_logo.png';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import EditSimulationData from './EditSimulationData';
import EdiBankSelection from './EditBankSelection';
import SpinnerDefault from '../../../../components/Spinner';
import AvailableBalance from '../../../../components/AvailableBalance';

const ResumeSimulation: React.FC = () => {
  const [formData, setFormData] = useState<any>(null);
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [isTurboEnabled, setIsTurboEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const subtitleTextClient = "Revise as informações e consulte.";
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditBankModal, setShowEditBankModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('formData') || '{}');
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBanks') || '[]');
    
    setFormData(savedFormData);
    setSelectedBanks(savedSelectedBanks);
  }, []);

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value: string) => {
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  if (!formData) return null;

  const handleToggleTurbo = () => {
    setIsTurboEnabled(prevState => !prevState);
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleEditBankModal = () => {
    setShowEditBankModal(true);
  };

  const handleUpdateFormData = (newData: any) => {
    setFormData(newData);
  };

  const handleUpdateBanks = (newBanks: any) => {
    setSelectedBanks(newBanks);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in local storage');
      return;
    }

    // Validar e formatar o requestedAmount
    const requestedAmount = isNaN(parseFloat(formData.requestedAmount)) ? undefined : parseFloat(formData.requestedAmount);

    const payload: any = {
      nitro: isTurboEnabled,
      client: {
        cpf: formData.cpf,
        birthDate: formData.birthDate,
        registration: formData.registration,
        incomeAmount: parseFloat(formData.income.toString()) || 0.0, // Garante que seja um float, com 0.0 como valor padrão
      },
      banks: {
        master: selectedBanks.includes('master'),
        bmg: selectedBanks.includes('bmg'),
        pan: selectedBanks.includes('pan'),
        c6: selectedBanks.includes('c6')
      },
      proposal: {
        covenantId: formData.covenantId,
        serviceCode: "010",
        requestedAmount: requestedAmount !== undefined ? parseFloat(requestedAmount.toString()) : undefined, // Garante que seja um float, se necessário
        installmentAmount: parseFloat(formData.parcelValue.toString()) || 0.0, // Garante que seja um float, com 0.0 como valor padrão
        installmentsQuantity: parseInt(formData.numberOfInstallments.toString(), 10) || 0, // Garante que seja um inteiro, com 0 como valor padrão
        formalizationSubtype: "DIGITAL_WEB"
      }
    };

    try {
      setLoading(true);
      const response = await fetch('https://acelereai.gfttech.com.br/api/proposal/simulation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      const result = await response.json();

      if (response.ok) {
        
          localStorage.setItem('apiResult', JSON.stringify(result));
          window.location.href = '/ResultadoSimulacao';
      
      } else {
        // Se a resposta for um erro 400, você pode exibir a mensagem de erro
        setErrorMessage(result.message || 'Ocorreu um erro.');
      }
    } catch (error) {
      console.error('Error during API request:', error);
      setErrorMessage('Ocorreu um erro inesperado.');
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <SpinnerDefault />} 
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.balanceConatiner} d-flex justify-content-end`}>
          <AvailableBalance />
        </div>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta Unitária</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Container className={`${styles.reviewContainer} mt-5`}>
            <Row>
              <Col md={6} className={`${styles.section} ${styles.leftSection}`}>
                <h5 className={styles.sectionTitle}>
                  Dados 
                  <ButtonPrimary content={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick} />
                </h5>
                <div className={styles.dataItem}>
                  <span className={styles.titleDataItem}>CPF:</span>
                  <span>{formData.cpf}</span>
                </div>
                <div className={styles.dataItem}>
                  <span className={styles.titleDataItem}>Matrícula:</span>
                  <span>{formData.registration}</span>
                </div>
                <div className={styles.dataItem}>
                  <span className={styles.titleDataItem}>Data de Nascimento:</span>
                  <span>{formatDate(formData.birthDate)}</span>
                </div>
                <div className={styles.dataItem}>
                  <span className={styles.titleDataItem}>Valor da Parcela:</span>
                  <span>{formatCurrency(formData.parcelValue)}</span>
                </div>
                <div className={styles.dataItem}>
                  <span className={styles.titleDataItem}>Quantidade de Parcelas:</span>
                  <span>{formData.numberOfInstallments}</span>
                </div>
              </Col>

              <Col md={6} className={`${styles.section} ${styles.rightSection}`}>
                <div className={styles.section}>
                  <h5 className={styles.sectionTitle}>
                    Bancos 
                    <ButtonPrimary content={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditBankModal} />
                  </h5>
                  <div>
                    {selectedBanks.map(bank => (
                      <span key={bank} className={styles.itemSpan}>{bank}</span>
                    ))}
                  </div>
                </div>

                <div className={styles.section}>
                  <h5 className={styles.sectionTitle}>
                    Convênio 
                    <ButtonPrimary content={<FontAwesomeIcon icon={faEdit} />} onClick={() => window.location.href = '/SelecaoConvenios'} />
                  </h5>
                  <span className={styles.itemSpan}>{formData.covenantDescription}</span>
                </div>

                <div className={styles.section}>
                  <h5 className={styles.sectionTitle}>
                    Produtos 
                    <ButtonPrimary content={<FontAwesomeIcon icon={faEdit} />} onClick={() => window.location.href = '/SelecaoProdutos'} />
                  </h5>
                  <span className={styles.itemSpan}>{formData.produto}</span>
                </div>
              </Col>
            </Row>
          </Container>
          {errorMessage && (
            <Row className='mt-3'>
              <Col className='d-flex justify-content-center'>
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </Col>
            </Row>
          )}
          <Row className='mt-5'>
            <Col className='d-flex justify-content-center mt-5'>
              {/*Consulta Turbo*/}
              <Form.Check 
                type="switch"
                id="turbo-switch"
                label="Consulta Turbo"
                checked={isTurboEnabled}
                onChange={handleToggleTurbo}
                className={`${styles.switchContainer} `}
              />
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Consultar</span></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </motion.div>

      <EditSimulationData 
        show={showEditModal} 
        handleClose={() => setShowEditModal(false)} 
        formData={formData} 
        updateFormData={handleUpdateFormData} 
      />
      <EdiBankSelection 
        show={showEditBankModal}
        handleClose={() => setShowEditBankModal(false)} 
        bankSelected={selectedBanks}
        updateBanks={handleUpdateBanks}
        />
    </>
  );
};

export default ResumeSimulation;
