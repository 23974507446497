import { Form } from "react-bootstrap";
import styles from './PersonalData.module.scss';
import { useEffect, useState } from "react";
import ButtonPrimary from "../../../../components/Buttons/ButtonPrimary";
import ToastWarning from "../../../../components/ToastWarning";

const PersonalData = () => {
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [nomFantasia, setNomFantasia] = useState('');
    //const [email, setEmail] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const token = localStorage.getItem('token');

    const buttonDesable = () => {
        return (
            name.trim()         === ''      || 
            cpf.trim()          === ''      || 
            nomFantasia.trim()  === ''      || 
            //email.trim()        === ''      || 
            cpf.length          <   14      
        );
    }

    const limitCpfCnpj = (value: string) => { //limita o número de dígitos no campo de cpf/cnpj
        value = value.replace(/\D/g, '');
    
        if (value.length > 14) {
            value = value.substring(0, 14);
        }
    
        return formatCpfCnpj(value);
    };

    const formatCpfCnpj = (value: string) => { //formata o cpf/cnpj digitado
        value = value.replace(/\D/g, '');
    
        if (value.length <= 11) {
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else if (value.length <= 14) {
            value = value.replace(/^(\d{2})(\d)/, '$1.$2');
            value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
            value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
            value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
        }
        return value;
    };

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && !buttonDesable()){
            handleData();
        }
    };

    const handleSubmit = async () => {
        try{
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/usuario/acelereai/dados-pessoais', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    full_name: name,
                    cpfCnpj: cpf,
                    fantasy_name: nomFantasia,
                    //email: email,
                }),
            });

            if (res.ok) {
                setToastMessage('Dados alterados com sucesso!');
            } else {
                setToastMessage('Erro ao alterar dados');
            }
        } catch(error){
            setToastMessage('Erro ao alterar dados');
            console.error(error);
        }

        handleData();
    }

    const handleData = async () => {
        try {
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/parceiro/dados-logado', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data = await res.json();
                const personalData = data.partnerData;

                setName(personalData.full_name);
                setCpf(personalData.cpf === '' ? formatCpfCnpj(personalData.cnpj) : formatCpfCnpj(personalData.cpf));
                //setEmail(personalData.user.email);
                setNomFantasia(personalData.fantasy_name);
                localStorage.setItem('IdUser', personalData.userId.toString());
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Carrega os dados ao montar o componente
    useEffect(() => {
        handleData();
    }, []);

    return (
        <section className="mt-5">
            <Form>
                <Form.Group className={styles.floatingLabel} controlId="newCpf">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={cpf} 
                        onChange={(e) => setCpf(limitCpfCnpj(e.target.value))}
                    />
                    <Form.Label>CPF/CNPJ</Form.Label>
                </Form.Group>

                <Form.Group className={styles.floatingLabel} controlId="newName">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={name} 
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Label>Nome</Form.Label>
                </Form.Group>

                <Form.Group className={styles.floatingLabel} controlId="newNomFantasia">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={nomFantasia} 
                        onKeyDown={keyPress}
                        onChange={(e) => setNomFantasia(e.target.value)}
                    />
                    <Form.Label>Nome Fantasia</Form.Label>
                </Form.Group>

                {/*<Form.Group className={styles.floatingLabel} controlId="newEmail">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Label>E-mail</Form.Label>
                </Form.Group>*/}

                <div className="d-flex justify-content-center">
                    <ButtonPrimary disabled={buttonDesable()} onClick={handleSubmit} content="Salvar Alterações" />
                </div>

            </Form>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </section>
    );
}

export default PersonalData;
