import React from 'react';
import styles from './Pagination.module.scss';
import { Col, Row } from 'react-bootstrap';

interface PaginationProps{
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
}

const getTotalPages = (totalItems: number, itemsPerPage: number) => Math.ceil(totalItems / itemsPerPage);

const Pagination: React.FC<PaginationProps> = ({
    totalItems,
    itemsPerPage,
    currentPage,
    onPageChange,
}) => {
    const totalPages = getTotalPages(totalItems, itemsPerPage);

    const handlePageClick = (page: number) => {
        onPageChange(page);
    };

    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
        paginationItems.push(
            <li
                key={number}
                className={`${styles.paginationItem} ${number === currentPage ? styles.active : ''}`}
                onClick={() => handlePageClick(number)}
            >
                {number}
            </li>
        );
    }
    
    return(
        <Row className="justify-content-center mt-3">
            <Col xs="auto">
                <ul className={styles.pagination}>
                    {paginationItems}
                </ul>
            </Col>
        </Row>
    )
}

export default Pagination;