import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import styles from './UnitaryFields.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../components/PageSubTitle';
import Select from 'react-select';
import logo from '../../../../assets/images/acelera_logo.png';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ProgressIndicator from '../../../../components/ProgressContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import AvailableBalance from '../../../../components/AvailableBalance';

const customSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #ccc !important',
    boxShadow: 'none',
    borderRadius: '0.5rem',
    padding: '0.55rem',
    fontSize: '1rem',
    '&:hover': {
      borderColor: '#ff5722 !important',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0.5rem',
    marginTop: '0.5rem',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ff5722 !important' : '#fff !important',
    color: state.isSelected ? '#fff !important' : '#000 !important',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000 !important',
  }),
};

export default function UnitaryFields({ selectedBanks = [] }: { selectedBanks?: string[] }) {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const bancos = localStorage.getItem('selectedBanks');
  const [cpf, setCpf] = useState('');
  const [registration, setRegistration] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [income, setIncome] = useState('');
  const [requestedAmount, setRequestedAmount] = useState('');
  const [parcelValue, setParcelValue] = useState('');
  const [numberOfInstallments, setNumberOfInstallments] = useState<any>(null);
  const [toastMessage, setToastMessage] = useState('');

  const totalSteps = 5;
  const currentStep = 2;

  const subtitleTextClient = "Preencha os campos para seguir com a consulta.";
  const subtitleTextSimulation = "Simulação";

  useEffect(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      const data = JSON.parse(storedData);
      setCpf(data.cpf || '');
      setRegistration(data.registration || '');
      setBirthDate(data.birthDate || '');
      setIncome(data.income ? formatCurrency(data.income) : '');
      setRequestedAmount(data.requestedAmount ? formatCurrency(data.requestedAmount) : '');
      setParcelValue(data.parcelValue ? formatCurrency(data.parcelValue) : '');
      setNumberOfInstallments(data.numberOfInstallments || null);
    }
  }, []);

  function formatCurrency(value: string): string {
    let v = value.replace(/\D/g, '');
    v = (parseFloat(v) / 100).toFixed(2) + '';
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return `R$ ${v}`;
  }

  const handleIncomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCurrency(value);
    setIncome(formattedValue);
  };

  const handleRequestedAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCurrency(value);
    setRequestedAmount(formattedValue);
  };

  const handleParcelValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCurrency(value);
    setParcelValue(formattedValue);
  };

  const handleSelectNumberOfInstallments = (selectedOption: { value: any; label: string } | null) => {
    if (selectedOption) {
      const value = selectedOption.value;
      setNumberOfInstallments(value);
    } else {
      setNumberOfInstallments(null);
    }
  };

  const validateForm = () => {
    let message = '';

    if (!cpf) {
      message += 'CPF é obrigatório.\n';
    }
    if (!registration) {
      message += 'Matrícula é obrigatória.\n';
    }
    if (!birthDate) {
      message += 'Data de Nascimento é obrigatória.\n';
    }
    if (!numberOfInstallments) {
      message += 'Quantidade de Parcelas é obrigatória.\n';
    }

    if (bancos?.includes("master")) {
      if (!requestedAmount) {
        message += 'Valor Solicitado é obrigatório quando o banco Master está selecionado.\n';
      }
    } else {
      if (!parcelValue) {
        message += 'Valor da Parcela é obrigatório.\n';
      }
    }

    if (message) {
      setToastMessage(message);
      return false;
    }

    return true;
  };

  const handleBack = (e: React.FormEvent) => {
    e.preventDefault();
    window.location.href = '/ConsultaUnitaria';
    localStorage.setItem('selectedBanks', JSON.stringify(bancos));
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      const [day, month, year] = birthDate.split('/');
      const formattedBirthDate = `${year}-${month}-${day}`;

      const formattedCpf = cpf.replace(/\D/g, '');

      const toFloat = (value: string) => parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.'));

      let formattedIncome = income === '' ? '10000' : income;
      formattedIncome = toFloat(formattedIncome).toFixed(2);

      const formattedRequestedAmount = toFloat(requestedAmount).toFixed(2);
      const formattedParcelValue = toFloat(parcelValue).toFixed(2);

      const formData = {
        cpf: formattedCpf,
        registration,
        birthDate: formattedBirthDate,
        income: formattedIncome,
        requestedAmount: formattedRequestedAmount,
        parcelValue: formattedParcelValue,
        numberOfInstallments
      };

      localStorage.setItem('formData', JSON.stringify(formData));

      window.location.href = '/SelecaoConvenios';
    } else {
      console.log('Exibindo toast de validação');
    }
  };

  const installmentOptions = [
    { value: 84, label: '84' },
    { value: 72, label: '72' },
    { value: 64, label: '64' },
    { value: 56, label: '56' },
    { value: 48, label: '48' },
    { value: 36, label: '36' },
    { value: 24, label: '24' },
    { value: 12, label: '12' }
  ];

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
         <div className={`${styles.balanceConatiner} d-flex justify-content-end`}>
          <AvailableBalance />
        </div>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta Unitária</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
       
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Form onSubmit={handleSubmit} className="col-sm-12 col-md-10 mt-5">
            <Row>
              <Col sm={12} md={6}>
                <Form.Group className={styles.floatingLabel} controlId="cpf">
                  <IMaskInput
                    mask="000.000.000-00"
                    className={`${styles.input} form-control`}
                    placeholder="CPF*"
                    value={cpf}
                    onAccept={(value: string) => setCpf(value)}
                  />
                  <Form.Label>CPF*</Form.Label>
                </Form.Group>
              </Col>

              <Col sm={12} md={6}>
                <Form.Group className={styles.floatingLabel} controlId="registration">
                  <Form.Control
                    type="text"
                    placeholder="Matrícula"
                    value={registration}
                    onChange={(e) => setRegistration(e.target.value)}
                  />
                  <Form.Label>Matrícula</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group className={styles.floatingLabel} controlId="birthDate">
                  <IMaskInput
                    mask="00/00/0000"
                    className={`${styles.input} form-control`}
                    placeholder="Data de Nascimento"
                    value={birthDate}
                    onAccept={(value: string) => setBirthDate(value)}
                  />
                  <Form.Label>Data de Nascimento</Form.Label>
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className={styles.floatingLabel} controlId="income">
                  <Form.Control
                    type="text"
                    className={`${styles.input} form-control`}
                    placeholder="Renda"
                    value={income}
                    onChange={handleIncomeChange}
                  />
                  <Form.Label>Renda</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-5 mb-3'>
              <PageSubTitle size='h5'>{subtitleTextSimulation}</PageSubTitle>  
            </div>
            <Row>
              <Col sm={12} md={4}>
                <Form.Group className={styles.floatingLabel} controlId="requestedAmount">
                  <Form.Control
                    className={`${styles.input} form-control`}
                    placeholder="Valor Solicitado"
                    value={requestedAmount}
                    onChange={handleRequestedAmountChange}
                  />
                  <Form.Label>Valor Solicitado</Form.Label>
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className={styles.floatingLabel} controlId="parcelValue">
                  <Form.Control
                    className={`${styles.input} form-control`}
                    placeholder="Valor da Parcela"
                    value={parcelValue}
                    onChange={handleParcelValueChange}
                  />
                  <Form.Label>Valor da Parcela</Form.Label>
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className={styles.floatingLabel} controlId="numberOfInstallments">
                  <Select
                    options={installmentOptions}
                    styles={customSelectStyles}
                    placeholder="Quantidade de Parcelas*"
                    onChange={handleSelectNumberOfInstallments}
                    value={installmentOptions.find(option => option.value === numberOfInstallments)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col className='d-flex justify-content-start mt-5'>
                <ButtonPrimary 
                  content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>} 
                  onClick={handleBack}
                />
              </Col>
              <Col className='d-flex justify-content-end mt-5'>
                <ButtonPrimary 
                  content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
            <Row>
              <Col className='mt-3 d-flex justify-content-center mt-5' md={12}>
                <ProgressIndicator totalSteps={totalSteps} currentStep={currentStep} />
              </Col>
            </Row>
          </Form>
          {toastMessage && (
            <ToastValidationSimulation message={toastMessage} />
          )}
        </Container>
      </motion.div>
    </>
  );
}
