import React, { useState, useEffect } from 'react';
import styles from './AvailableBalance.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';

const AvailableBalance: React.FC = () => {
  const [fgts, setFgts] = useState(0);
  const [inss, setInss] = useState(0);
  const [oportunity, setOportunity] = useState(0);
  const [convenant, setConvenant] = useState(0);
  const [isVisible, setIsVisible] = useState<boolean>(true); // Estado para controlar a visibilidade do saldo

  const cash = fgts + inss + oportunity + convenant;

  // Verifica o valor do localStorage quando o componente carrega
  useEffect(() => {
    const storedVisibility = localStorage.getItem('balanceVisibility');
    if (storedVisibility) {
      setIsVisible(storedVisibility === 'true');
    }
  }, []);

  // Função para alternar a visibilidade do saldo
  const toggleVisibility = () => {
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    localStorage.setItem('balanceVisibility', newVisibility.toString());
  };

  const handleCash = async () => {
    const token = localStorage.getItem('token');
      try{
        const res = await fetch(`https://webservices.gfttech.com.br/api/v1/parceiro-credito`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if(res.ok){
          const data = await res.json();
          const partnerCredit = data.PartnerCredit[0];
          setConvenant(partnerCredit.covenant_credit || 0);
          setFgts(partnerCredit.fgts || 0);
          setInss(partnerCredit.inss_credit || 0);
          setOportunity(partnerCredit.oportunity_credit || 0);
        } else{
          console.log('Erro ao buscar saldo', res.statusText);
        }
      } catch(error){
        console.error('Erro ao buscar saldo', error);
      }
  }

  useEffect(() => {
    handleCash();
  }, []);

  return (
    <div className={`${styles.balanceContainer} d-flex justify-content-between align-items-center`}>
      <span className={styles.label}>Saldo Disponível:</span>
      <div className="d-flex align-items-center">
        <span className={styles.balance}>
          {isVisible ? cash : '••••••'}
        </span>
        <FontAwesomeIcon 
          icon={isVisible ? faEye : faEyeSlash} 
          onClick={toggleVisibility} 
          className={styles.icon} 
        />

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" variant="primary" className={styles.dropdownIcon}>
            <FontAwesomeIcon icon={faChevronDown} className={styles.dropdowfontAwesome} />
          </Dropdown.Toggle>

          <Dropdown.Menu className={styles.dropdown}>
            <div>
              <Dropdown.Item className={`d-flex justify-content-between ${styles.dropdownItem}`}>
                FGTS: 
                <span>{fgts}</span>
              </Dropdown.Item>
              <Dropdown.Item className={`d-flex justify-content-between ${styles.dropdownItem}`}>
                INSS: 
                <span>{inss}</span>  
              </Dropdown.Item>
              <Dropdown.Item className={`d-flex justify-content-between ${styles.dropdownItem}`}>
                Convênios: 
                <span>{convenant}</span>
              </Dropdown.Item>
              <Dropdown.Item className={`d-flex justify-content-between ${styles.dropdownItem}`}>
                Oportunidade: 
                <span>{oportunity}</span>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </div>
  );
}

export default AvailableBalance;
