import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styles from './QuickAccess.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ToastWarning from '../../../components/ToastWarning';
import AddCard from './Cards_Quick_Access/Add_Card';
import Cards from './Cards_Quick_Access/Cards';
import PageTitle from '../../../components/PageTitle';

interface CardData {
    card_id: number;
    card_name: string;
    user_cards_id: number;
}

const QuickAccess: React.FC = () => {
    const [cards, setCards] = useState<CardData[]>([]); 
    const [iconState, setIconState] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showAddCardModal, setShowAddCardModal] = useState(false);

    const toggleIcon = () => setIconState((prev) => !prev);
    
    const handleResponse = (response: string) => {
        setToastMessage(response); // Retorna a resposta da exclusão do card no acesso rápido
        handleCard();
    };

    const handleModalClose = () => {
        setShowAddCardModal(false);
        handleCard();
    };

    const handleCard = async () => {
        const token = localStorage.getItem('token');
        try {
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/acelerai/card/user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await res.json(); 
            if (res.ok) {
                const CardsData = data.map((item: any) => ({
                    card_id: item.card.card_id,
                    card_name: item.card.card_name,
                    user_cards_id: item.user_cards_id,
                })); 

                setCards(CardsData);
            } else {
                console.log(res.statusText);
            }
        } catch {
            console.log('Nenhuma resposta retornada!');
        }
    } 

    useEffect(() => {
        handleCard();
    }, []); 

    return (
        <Container className={styles.mainSection}>
            <div>
                <PageTitle size="h4" > Acesso rápido </PageTitle>
                <FontAwesomeIcon icon={faPenToSquare} className={styles.icon} onClick={toggleIcon} />
            </div>
            <div className="d-flex justify-content-between">
                <Container className={`${styles.cardsContainer} row`}>
                    <Cards
                        iconState={iconState}
                        cardsData={cards}
                        onResponse={handleResponse}
                    />
                    {cards.length < 4 && <AddCard onClose={handleModalClose} />}
                </Container>
            </div>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </Container>
    ) 
};

export default QuickAccess;
