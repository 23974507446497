import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ResultTableSearchCovenant from "./ResultTable";
import axios from 'axios';
import ModalErrorBatchFile from "../../Modals/Errors/ModalErrorBatchFile";

export default function SearchBatchTab() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);  // Add loading state
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === 'xlsx') {
        setSelectedFile(file);
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setShowErrorModal(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);  // Set loading state to true when the request starts
    try {
      const response = await axios.post(
        "http://webapi.gfttech.com.br:3333/api/v2/master/customer/covenants",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      setData(response.data);
    } catch (error) {
      console.error("Error during file upload:", error);
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);  // Stop the spinner after request completes
    }
  };

  return (
    <>
      <Row className="d-flex justify-content-center mt-5">
        <Col sm={8} md={10}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Col>
        <Col sm={4} md={2}>
          <ButtonPrimary
            content={<><FontAwesomeIcon icon={faSearch} /></>}
            onClick={handleSubmit}
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-center mt-5">
        {isLoading ? (  // Display the spinner while loading
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          data.length > 0 && <ResultTableSearchCovenant data={data} />  // Display the table only when data is loaded
        )}
      </Row>

      {showErrorModal && (
        <ModalErrorBatchFile
          show={showErrorModal}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
}
