import { useState, useEffect } from 'react';
import NavBar from "../../../../components/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import styled from 'styled-components';
import styles from './SelectBanks.module.scss';
import logo from '../../../../assets/images/Logo_Quero_Mais_Crédito.png';
import PageTitle from "../../../../components/PageTitle";
import BankListSimulation from "../../../Query/UnitaryQuery/BankListSimulation";
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageSubTitle from '../../../../components/PageSubTitle';
import { motion } from 'framer-motion';

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  background-color: #007f5b;
  border: 2px solid #007f5b;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: #007f5b;
  }
`;

const CustomLabel = styled.label`
  font-size: 1.25rem;
  color: #007f5b;
  margin-left: 0.5rem;
  font-weight: bold;
  font-family: 'PlusJakartaSans-Light', sans-serif;
`;

export default function SelectBanksInss() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const [showUnitaryFields, setShowUnitaryFields] = useState<boolean>(false);

  localStorage.setItem("covenantId","3")

  useEffect(() => {
    // Atualiza o local storage sempre que selectedBanks mudar
    localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
  }, [selectedBanks]);

  const handleSelectAllChange = () => {
    setSelectAll(prev => !prev);
  };

  const handleBankSelection = (selected: string[]) => {
    setSelectedBanks(selected);
  };

  const handleProceed = () => {
    if (selectedBanks.length > 0) {
      window.location.href = '/ConsultaUnitaria/Inss/DadosCliente'
    }
  };


  const isButtonDisabled = selectedBanks.length === 0;

  const subtitleText = "Selecione um ou mais bancos para realizar a sua consulta.";

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-3">
        <img src={logo} style={{maxWidth: 220}} alt="Logo_Aceleraai" />
      </Container>

      <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
        <div className="mt-5 d-flex justify-content-between align-items-center">
          <PageTitle size="h2">Consulta Unitária</PageTitle>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <CustomLabel htmlFor="selectAll">Selecionar Todos</CustomLabel>
          </div>
        </div>
        <div className='mb-5'>
          <PageSubTitle size='h5'>{subtitleText}</PageSubTitle>
        </div>
        <Row className='mb-5'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BankListSimulation isSelectAll={selectAll} onSelectionChange={handleBankSelection} />
          </motion.div>
        </Row>

        <Row className='mt-5'>
          <Col className='d-flex justify-content-end mt-2'>
            <ButtonPrimary 
              content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
              disabled={isButtonDisabled}
              onClick={handleProceed}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}