import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import styles from './ClientData.module.scss';
import { motion } from 'framer-motion';
import logo from '../../../../assets/images/Logo_Quero_Mais_Crédito.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import { IMaskInput } from 'react-imask';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove non-numeric characters
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  let rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  return rest === parseInt(cpf.substring(10, 11));
}

export default function ClientDataCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const subtitleTextClient = "Informe o CPF do seu cliente.";

  const handleBack = () => {
    window.location.href = "/ConsultaUnitaria/Convenios";
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setToastMessage('');

    if (!cpf) {
      setToastMessage('Por favor, preencha o CPF.');
      return;
    }

    if (!isValidCPF(cpf)) {
      setToastMessage('CPF inválido.');
      return;
    }

    const cpfOnlyNumbers = cpf.replace(/[^\d]/g, '');

    // Verifica se o CPF é 08694682354
    if (cpfOnlyNumbers === '08694682354') {
      window.location.href = `/ConsultaUnitaria/Convenio/Resultado`;
    } else {
      setToastMessage('CPF não encontrado.');
    }
  };
  
  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} style={{maxWidth: 220}} alt="Logo_Aceleraai" />
      </Container>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Dados do Cliente</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='d-flex justify-content-center'>
            <Col sm={12} md={8}>
              <Form.Group className={styles.floatingLabel} controlId="cpf">
                <IMaskInput
                  mask="000.000.000-00"
                  className={`${styles.input} form-control`}
                  placeholder="CPF*"
                  value={cpf}
                  onAccept={(value: string) => setCpf(value)}
                />
                <Form.Label>CPF*</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>}
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-center mt-5'>
            {loading && 
              <div> 
                <Spinner animation="border" />
              </div>}
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>}
                onClick={handleSubmit}
              />
        
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
