import { useEffect, useState } from "react";
import { Table, Spinner, Badge, OverlayTrigger, Popover } from "react-bootstrap";
import styles from './ReturnList.module.scss';
import { useLocation } from "react-router-dom";

interface SimulationData {
  cpf: string;
  bank: string;
  covenant: string;
  serviceCode: string;
  netAmount: string | null;
  installmentQuantity: number | string | null;
  installmentAmount: string | null;
  clientRate: string | null;
  simulationId: string;
  simulationDate: string;
  error?: string;
}

export default function ReturnList() {
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [processedCpfs, setProcessedCpfs] = useState<number>(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lote = searchParams.get("lote");
  const totalCpfs = JSON.parse(searchParams.get("tC") || '0');

  useEffect(() => {
    const fetchSimulations = async () => {
      try {
        const response = await fetch(`https://acelereai.gfttech.com.br/api/v2/proposal/simulation?by=batch&batchId=${lote}`);

        if (!response.ok) {
          throw new Error(`Erro ao carregar os dados. Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.length > 0) {
          setSimulations(data);
          setLoading(false);

          const uniqueCpfs = new Set(data.map((simulation: SimulationData) => simulation.cpf));
          const processedCpfsCount = uniqueCpfs.size;
          setProcessedCpfs(processedCpfsCount);
          localStorage.setItem('processedCpfs', JSON.stringify(processedCpfsCount));
        }
      } catch (err) {
        console.log(err);
        if (retryCount < 3) {
          setRetryCount(retryCount + 1);
        }
      }
    };

    if (processedCpfs < totalCpfs) {
      fetchSimulations();
    }
  }, [retryCount, lote, processedCpfs, totalCpfs]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedProcessedCpfs = JSON.parse(localStorage.getItem('processedCpfs') || '0');

      if (storedProcessedCpfs < totalCpfs) {
        setRetryCount(prevCount => prevCount + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [totalCpfs]);

  const formatCurrency = (value: string | null) => {
    if (value === null) return "-";
    const number = parseFloat(value);
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('pt-BR')} ${date.toLocaleTimeString('pt-BR')}`;
  };

  const renderErrorBadge = (error?: string) => {
    if (!error) return null;

    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>{error}</Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
        <Badge bg="danger" className="ms-2">X</Badge>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.scrollableTable}>
          <Table className={`${styles.tableStyle} table table-responsive`}>
            <thead>
              <tr>
                <th>CPF</th>
                <th>BANCO</th>
                <th>CONVÊNIO</th>
                <th>PRODUTO</th>
                <th>VLR. TOTAL</th>
                <th>VLR. PARCELAS</th>
                <th>PARCELAS</th>
                <th>TAXA</th>
                <th>DATA SIMULAÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                      </Spinner>
                    </div>
                  </td>
                </tr>
              ) : simulations.length > 0 ? (
                simulations.map((simulation, index) => (
                  <tr key={index}>
                    <td>
                      {simulation.cpf}
                      {renderErrorBadge(simulation.error)}
                    </td>
                    <td>{simulation.bank.toUpperCase()}</td>
                    <td>{simulation.covenant}</td>
                    <td>{simulation.serviceCode}</td>
                    <td>{formatCurrency(simulation.netAmount)}</td>
                    <td>{formatCurrency(simulation.installmentAmount)}</td>
                    <td>{simulation.installmentQuantity || "-"}</td>
                    <td>{simulation.clientRate ? `${simulation.clientRate}%` : "-"}</td>
                    <td>{formatDateTime(simulation.simulationDate)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                      </Spinner>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
