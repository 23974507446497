import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface Bank {
  bankId: string;
  path: string;
  bankName: string;
}

interface BankListSimulationCovenantProps {
  isSelectAll: boolean;
  onSelectionChange: (selectedIds: string[]) => void;
}

const BankCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 35px;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #007f5b;
  }

  &.selected {
    box-shadow: 0 0 1rem #007f5b;
  }

  @media (max-width: 764px) {
    max-width: 15rem;
    margin-left: 0;
    margin-bottom: 1rem;
    padding: 0.8rem;
  }

  @media (max-width: 480px) {
    max-width: 13rem;
    padding: 0.6rem;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #007f5b;
  }

  @media (max-width: 764px) {
    font-size: 1.1rem;
    top: 0.4rem;
    right: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    top: 0.3rem;
    right: 0.3rem;
  }
`;

const BankName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #007f5b;

  @media (max-width: 764px) {
    font-size: 0.9rem;
    margin-top: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }
`;

const BankLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;

  @media (max-width: 764px) {
    max-width: 80px;
    max-height: 80px;
  }

  @media (max-width: 480px) {
    max-width: 60px;
    max-height: 60px;
  }
`;

const formatBankName = (name: string) => {
  return name
    .replace(/BANCO BMG/g, 'bmg')
    .replace(/BANCO|BANK|SOCIETY|CORP/g, '')
    .trim()
    .toLowerCase();
};

const BankListSimulationCovenant: React.FC<BankListSimulationCovenantProps> = ({ isSelectAll, onSelectionChange }) => {
  const [selectedBankNames, setSelectedBankNames] = useState<string[]>([]);
  const [selectedBankIds, setSelectedBankIds] = useState<string[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const covenantId = localStorage.getItem('covenantId')

  useEffect(() => {
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBankNames') || '[]');
    const savedSelectedBankIds = JSON.parse(localStorage.getItem('selectedBankIds') || '[]');
    setSelectedBankNames(savedSelectedBanks);
    setSelectedBankIds(savedSelectedBankIds);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedBankNames', JSON.stringify(selectedBankNames));
    localStorage.setItem('selectedBankIds', JSON.stringify(selectedBankIds));
    onSelectionChange(selectedBankNames);
  }, [selectedBankNames, selectedBankIds, onSelectionChange]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await fetch(`https://acelereai.gfttech.com.br/api/v1/bank/find-by-covenant-id?covenantId=${covenantId}`);
        if (!response.ok) throw new Error('Failed to fetch banks');

        const data = await response.json();
      
        setBanks(data.map((bank: Bank) => ({
          bankId: bank.bankId,
          bankName: bank.bankName,
          path: bank.path,
        })));
      } catch (error) {
        setError(true);
        console.error('Error fetching bank data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, [covenantId]);

  useEffect(() => {
    if (isSelectAll) {
      const allBanks = banks.map(bank => formatBankName(bank.bankName));
      setSelectedBankNames(allBanks);
      setSelectedBankIds(banks.map(bank => bank.bankId));
    } else {
      setSelectedBankNames([]);
      setSelectedBankIds([]);
    }
  }, [isSelectAll, banks]);

  const handleSelectBank = (bankName: string, bankId: string) => {
    const formattedName = formatBankName(bankName);

    setSelectedBankNames(prevSelected =>
      prevSelected.includes(formattedName)
        ? prevSelected.filter(name => name !== formattedName)
        : [...prevSelected, formattedName]
    );

    setSelectedBankIds(prevSelected =>
      prevSelected.includes(bankId)
        ? prevSelected.filter(id => id !== bankId)
        : [...prevSelected, bankId]
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Bancos não cadastrados para o convênio selecionado.</p>;

  return (
    <Row className='d-flex justify-content-center mt-5 mb-5'>
      {banks.map(bank => (
        <BankCard
          sm={12} md={2} key={bank.bankId}
          onClick={() => handleSelectBank(bank.bankName, bank.bankId)}
          className={selectedBankNames.includes(formatBankName(bank.bankName)) ? 'selected' : ''}
        >
          <BankLogo src={bank.path} alt={`${bank.bankName} logo`} />
          <BankName>{bank.bankName}</BankName>
          {selectedBankNames.includes(formatBankName(bank.bankName)) && (
            <CheckIcon icon={faCheck} className="selected" />
          )}
        </BankCard>
      ))}
    </Row>
  );
};

export default BankListSimulationCovenant;
