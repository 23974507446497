import { useEffect, useState } from "react";
import { Table, Spinner, Container } from "react-bootstrap";
import styles from './BatchSimulations.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SpinnerDefault from "../../../components/Spinner";
import ButtonGrayUltimate from "../../../components/Buttons/ButtonGrayUltimate";
import Pagination from '../../../components/Pagination';
import FilterBatch from "./FilterBatch";
import ViewDataBatchSimulation from "../../../components/Modals/Simulations/ViewDataBatchSimulation";

interface SimulationData {
  _id: string;
  batchId: string;
  rows: number;
  date: string;
}

export default function BatchSimulations() {
  
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [filteredSimulations, setFilteredSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');

  useEffect(() => {
    const fetchSimulations = async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token não encontrado');
        }

        const partnerResponse = await fetch('https://webservices.gfttech.com.br/api/v1/parceiro/dados-logado', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!partnerResponse.ok) {
          throw new Error('Erro ao buscar dados do parceiro');
        }

        const partnerData = await partnerResponse.json();
        const partnerId = partnerData.partnerData.partner_id;

        const response = await fetch(`https://acelereai.gfttech.com.br/api/v1/batch/find-by-user?userId=${partnerId}&userType=Partner`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados');
        }
        
        const data: SimulationData[] = await response.json();
        setSimulations(data);
        setFilteredSimulations(data); // Inicializa as simulações filtradas com todas as simulações
      } catch (error) {
        console.error('Erro ao buscar simulações:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSimulations();
  }, []);

  // Função para filtrar as simulações
  const handleFilter = (search: string, startDate: string, endDate: string) => {
    let filtered = simulations;

    // Filtro pelo ID do lote
    if (search) {
      filtered = filtered.filter(simulation => simulation.batchId.includes(search));
    }

    // Filtro pela data inicial
    if (startDate) {
      filtered = filtered.filter(simulation => new Date(simulation.date) >= new Date(startDate));
    }

    // Filtro pela data final
    if (endDate) {
      filtered = filtered.filter(simulation => new Date(simulation.date) <= new Date(endDate));
    }

    setFilteredSimulations(filtered);
    setCurrentPage(1); // Reseta a página atual para 1 ao aplicar o filtro
  };

  const totalPages = Math.ceil(filteredSimulations.length / itemsPerPage);
  const currentSimulations = filteredSimulations.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleViewBatch = (batchId: string) => {
    setSelectedBatchId(batchId);
    setModalShow(true);
  };

  return (
    <>  
        <FilterBatch onFilter={handleFilter} />

        <div className={`${styles.tableContainer} mt-5`}>
          <div className={styles.scrollableTable}>
            <Table className={`${styles.tableStyle} table table-responsive`}>
              <thead>
                <tr>
                  <th>ID DO LOTE</th>
                  <th>DATA E HORÁRIO DO LOTE</th>
                  <th>CLIENTES</th>
                  <th>AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Carregando...</span>
                        </Spinner>
                      </div>
                    </td>
                  </tr>
                ) : currentSimulations.length > 0 ? (
                  currentSimulations.map((simulation) => (
                    <tr key={simulation._id}>
                      <td>{simulation.batchId}</td>
                      <td>{new Date(simulation.date).toLocaleString('pt-BR')}</td>
                      <td>{simulation.rows} clientes</td>
                      <td>
                        <ButtonGrayUltimate 
                          content={<><FontAwesomeIcon icon={faInfoCircle} /></>} 
                          onClick={() => handleViewBatch(simulation.batchId)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="d-flex justify-content-center">
                        <SpinnerDefault />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <Pagination
            currentPage={currentPage}
            totalItems={totalPages}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </div>

        <ViewDataBatchSimulation 
          show={modalShow} 
          onHide={() => setModalShow(false)} 
          batchId={selectedBatchId} 
        />
    </>
  );
}
