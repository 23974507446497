import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import logo from '../../../../assets/images/acelera_logo.png';
import inssLogo from '../../../../assets/images/inss-logo.png';
import panLogo from '../../../../assets/images/banco pan.png';
import bmgLogo from '../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../assets/images/c6-bank.png';
import masterLogo from '../../../../assets/images/banco master.png';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';

const ResultSimulation: React.FC = () => {
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const subtitleTextClient = "Lista de Oportunidades.";

  useEffect(() => {
    const storedResult = localStorage.getItem('apiResult');
    const storedFormData = localStorage.getItem('formData');
    
    if (storedResult) {
      setResult(JSON.parse(storedResult));
    }

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }

    // Cleanup function to clear localStorage when component unmounts
    return () => {
      localStorage.removeItem('selectedBanks');
    };
  }, []);

  if (!result || !formData) {
    return <div>Loading...</div>;
  }

  // Destructure to separate simulationId from the results
  const { simulationId, ...simulationResult } = result;

  const getLogoPath = (bank: string) => {
    switch (bank) {
      case 'pan': return panLogo;
      case 'c6': return c6Logo;
      case 'master': return masterLogo;
      case 'bmg': return bmgLogo;
      default: return '';
    }
  };

  const getCovenantLogoPath = () => {
    return formData.covenantId === '3' ? inssLogo : '';
  };

  const formatCurrency = (value: any) => {
    if (typeof value === 'number') {
      return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
    const numberValue = parseFloat(value);
    return isNaN(numberValue)
      ? 'N/A'
      : `R$ ${numberValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta Unitária</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className={`${styles.resultSimulation} mt-5`}>
            {Object.keys(simulationResult).map(key => {
              const bankResult = simulationResult[key];
              const logoPath = getLogoPath(key);
              const covenantLogoPath = getCovenantLogoPath();

              if (bankResult.status === 'failed') {
                return (
                  <Col xs={12} sm={6} md={4} key={key} className="mb-3">
                    <Card className={styles.cardContainer}>
                      <Card.Header className={styles.cardHeader}>
                        Novo
                      </Card.Header>
                      <Card.Body className={styles.cardBody}>
                        <div className={styles.logoContainer}>
                          <img src={logoPath} alt={`${key} logo`} className={styles.bankLogo} />
                          {covenantLogoPath && <img src={covenantLogoPath} alt="covenant logo" className={styles.covenantLogo} />}
                        </div>
                        <div style={{ color: 'red' }}>
                          {bankResult.error}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }

              if (!bankResult.data) {
                return (
                  <Col xs={12} sm={6} md={4} key={key} className="mb-3 mt-5">
                    <Card className={styles.cardContainer}>
                      <Card.Header className={styles.cardHeader}>
                        Novo
                      </Card.Header>
                      <Card.Body className={styles.cardBody}>
                        <div className={styles.logoContainer}>
                          <img src={logoPath} alt={`${key} logo`} className={styles.bankLogo} />
                          {covenantLogoPath && <img src={covenantLogoPath} alt="covenant logo" className={styles.covenantLogo} />}
                        </div>
                        <div style={{ color: 'red' }}>
                          Dados não disponíveis para {key}.
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }

              return (
                <Col xs={12} sm={12} md={4} key={key} className="mb-3">
                  <Card className={styles.cardContainer}>
                    <Card.Header className={styles.cardHeader}>
                      NOVO
                    </Card.Header>
                    <Card.Body className={styles.cardBody}>
                      <div className={styles.logoContainer}>
                        <img src={logoPath} alt={`${key} logo`} className={styles.bankLogo} />
                        {covenantLogoPath && <img src={covenantLogoPath} alt="covenant logo" className={styles.covenantLogo} />}
                      </div>
                      <div className={styles.netAmount}>
                        {formatCurrency(bankResult.data.netAmount)}
                      </div>
                      <Container className={styles.textAmount}>
                        <p>{bankResult.data.installmentQuantity || 'N/A'} parcelas de R$ {bankResult.data.installmentAmount || 'N/A'}</p>
                        <p>{bankResult.data.clientRate || 'N/A'}% ao mês</p>
                      </Container>
                    </Card.Body>
                  </Card>
                  <div>
                    <Button variant="primary" className={`${styles.buttonFirst} me-3`}>Guardar Consulta</Button>
                    <Button variant="secondary" className={styles.buttonSecondary}>Digitar</Button>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default ResultSimulation;
