import { useState } from 'react';
import styles from './CreateForm.module.scss';
import { Col, Row } from 'react-bootstrap';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../../components/ToastWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CreateAccess from '../../../../../../components/Modals/Others/CreateAccess';

const CreateForm = ({onProceed}: {onProceed: (page: string) => void;}) =>{
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [key, setKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const bankName = localStorage.getItem('bankName');
    const bankId = localStorage.getItem('bankId');

    const loginChange = (e: any) => {
        setLogin(e.target.value);
    }

    const passwordChange = (e: any) => {
        setPassword(e.target.value);
    }

    const keyChange = (e: any) => {
        setKey(e.target.value);
    }
    
    const secretKeyChange = (e: any) => {
        setSecretKey(e.target.value);
    }

    const passWordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleModal = () => {
        setShowModal(true);
        setTimeout(()=> {
            handleCloseModal();
            onProceed('access');
        }, 3000);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const keysEnabled = Number(bankId) === 1 || Number(bankId) === 7;

    const buttonDesable = () => {
        const fieldsIvalid = login.trim() === '' || password.trim() === ''; //desabilita o botão caso os campos de login e senha não estejam preenchidos
        const keysFieldInvalid = keysEnabled && (key.trim() === '' || secretKey.trim() === ''); //verifica qual o banco selecionado, para então, tornar obrigatório o preenchimento das keys

        return fieldsIvalid || keysFieldInvalid;
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try{
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/acesso-usuario-banco', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    bank_id: bankId,
                    user: login, 
                    password: password, 
                    api_key: key,  
                    secret_key: secretKey
                }),
            });
            if(res.ok){
                handleModal();
                console.log(res);
            } else{
                setToastMessage('Falha ao enviar dados!');
            }
            return res.json();
        } catch(error) {
            setToastMessage('Falha ao enviar dados!');
            console.error(error);
        }
    }

    return(
        <>
            <div className={`${styles.title} d-flex justify-content-center mt-3 mb-2`}>
                <h2>{bankName}</h2>
            </div>
            <div className={styles.floatingLabel}>
                <input 
                    type="text" 
                    id="login" 
                    placeholder=" "
                    value={login}
                    onChange={loginChange}
                    className={`form-control ${styles.input}`}
                    />
                <label htmlFor="login">Login</label>
            </div>
            <div className={styles.floatingLabel}>
            <input 
                    type={showPassword ? 'text' : 'password'}
                    id="senha" 
                    placeholder=" "
                    value={password}
                    onChange={passwordChange}
                    className={`form-control ${styles.input}`}
                    />
                <label htmlFor="senha">Senha</label>
                <div
                    className={`${styles.icon}`}
                    onClick={passWordVisibility}
                >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </div>
            </div>
            {keysEnabled && (
                <>
                    <div className={styles.floatingLabel}>
                        <input 
                            type="text" 
                            id="key" 
                            placeholder=" "
                            value={key}
                            onChange={keyChange}
                            className={`form-control ${styles.input}`}
                        />
                        <label htmlFor="key">Key</label>
                    </div>
                    <div className={styles.floatingLabel}>
                        <input 
                            type="text" 
                            id="secretKey" 
                            placeholder=" "
                            value={secretKey}
                            onChange={secretKeyChange}
                            className={`form-control ${styles.input}`}
                        />
                        <label htmlFor="secretKey">Secret Key</label>
                    </div>
                </>
            )}
            <Row>
                <Col className="d-flex justify-content-center mt-3">
                    <ButtonPrimary
                        disabled={buttonDesable()}
                        content="Salvar informações" 
                        onClick={handleSubmit}
                    />
                </Col>

                <CreateAccess show={showModal} onClose={() => handleCloseModal()}/>
            </Row>
            
        {toastMessage && <ToastWarning message={toastMessage} />}
        </>
    )
}

export default CreateForm;