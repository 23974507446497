import React, { useEffect, useState } from "react";
import { Modal, Table, Alert, Spinner, Form, Accordion, Badge, Row, Col } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import Pagination from "../../../Pagination";
import styles from "./ViewDataBatchSimulation.module.scss";
import PageSubTitle from "../../../PageSubTitle";
import ToastWarning from "../../../ToastWarning";
import { BankDataEntries, BankDataResult, Simulation } from "./interface";

export interface ViewDataBatchSimulationProps {
  show: boolean;
  batchId: string;
  onHide: () => void;
}

const isBankDataResult = (data: any): data is BankDataResult => {
  return data && typeof data === "object" && "status" in data;
};

const ViewDataBatchSimulation: React.FC<ViewDataBatchSimulationProps> = ({ show, batchId, onHide }) => {
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [filteredSimulations, setFilteredSimulations] = useState<Simulation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchCpf, setSearchCpf] = useState<string>("");
  const [toastMessage, setToastMessage] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    if (show) {
      setLoading(true);
      setError(null);
      fetchSimulations();
      setToastMessage('');
    }
  }, [show, batchId]);

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`https://acelereai.gfttech.com.br/api/v1/proposal/simulation/find-by-batch?batchId=${batchId}`);
      const contentType = response.headers.get("content-type");

      if (!response.ok) {
        throw new Error(
          "Falha ao buscar simulações. Por favor, tente novamente."
        );
      }

      if (contentType && contentType.includes("application/json")) {
        const data: Simulation[] = await response.json();
        setSimulations(data);
        setFilteredSimulations(data);
      } else {
        throw new Error("A resposta da API não está no formato JSON esperado");
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchCpf(value);
    const filtered = value
      ? simulations.filter((simulation) => simulation.cpf.includes(value))
      : simulations;

    setFilteredSimulations(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleExport = async () => {
    try {
      const exportData = filteredSimulations.flatMap((simulation) =>
        simulation.conditions?.conditions //verifica se o compo conditions existe 
          ? Object.entries(simulation.conditions.conditions).flatMap(
              ([bankKey, bankData]) =>
                Array.isArray(bankData)
                  ? bankData
                      .filter((product) => product.status === "success") // Filtrar apenas os que estiverem com status 'success'
                      .map((product) => ({
                        cpf: simulation.cpf,
                        covenantDescription: simulation.covenantDescription,
                        bank: bankKey,
                        product: product.product,
                        installmentQuantity: product.installmentQuantity || "Indisponível",
                        installmentAmount: product.installmentAmount || "Indisponível",
                        netAmount: product.netAmount || "Indisponível",
                        grossAmount: product.grossAmount || "Indisponível",
                        clientRate: product.clientRate || "Indisponível",
                      }))
                  : []
            )
          : []
      );

      const requestBody = {
        fileName: "detalhe_lote",
        pageName: "Simulações Lote",
        pageHeader: [
          "CPF",
          "Convênio",
          "Banco",
          "Produto",
          "Parcelas",
          "Valor das Parcelas",
          "Valor Líquido",
          "Valor Bruto",
          "Taxa",
        ],
        data: exportData,
      };

      const res = await fetch('http://192.168.254.88:3333/api/portal/createFile/excel',{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (res.ok) {
        const blob = await res.blob(); 
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "detalhe_lote.xlsx"); 
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
          setToastMessage('Arquivo criado com sucesso!')
        } else {
          console.warn("link não foi adicionado corretamente");
        }
      } else {
        console.log("Erro ao criar arquivo");
        setToastMessage("Erro ao criar arquivo");
      }
    } catch (error) {
      console.error("Erro de exportação", error);
    }
  };

  const formatCurrency = (value: number | string | null | undefined) => {
    if (value == null || value === undefined || value === "")
      return "Indisponível";
    const numberValue = typeof value === "string" ? parseFloat(value) : value;
    if (isNaN(numberValue)) return "Indisponível"; // Se não for um número, retorna 'Indisponível'
    return numberValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const renderBankData = (bankKey: string, bankData: BankDataEntries) => {
    if (!bankData) return null;

    if (Array.isArray(bankData)) {
      return bankData.map((data, index) => {
        if (data.status === "failed") {
          return (
            <tr key={`${bankKey}-${index}`}>
              <td colSpan={6} style={{ color: "red" }}>
                {data.error || "Erro desconhecido"}
              </td>
            </tr>
          );
        }

        return (
          <tr key={`${bankKey}-${index}`}>
            <td>{data.product || "Indisponível"}</td>
            {data.product === "cartão rmc" ||
            data.product === "Cartão RMC/RCC" ? (
              <>
                <td>
                  Limite:{" "}
                  {formatCurrency(data.installmentQuantity) || "Indisponível"}
                </td>
                <td> - </td>
                <td>{formatCurrency(data.netAmount)} (Saque)</td>
              </>
            ) : (
              <>
                <td>{data.installmentQuantity || "Indisponível"}</td>
                <td>{formatCurrency(data.installmentAmount)}</td>
                <td>{formatCurrency(data.netAmount)}</td>
              </>
            )}
            <td>{formatCurrency(data.grossAmount)}</td>
            <td>{data.clientRate || "Indisponível"}</td>
          </tr>
        );
      });
    } else if (isBankDataResult(bankData)) {
      return (
        <tr key={bankKey}>
          <td>{bankKey}</td>
          <td colSpan={6} style={{ color: "red" }}>
            {bankData.error || "Indisponível"}
          </td>
        </tr>
      );
    }
    return null;
  };

  const getUniqueCovenantAndDate = () => {
    if (filteredSimulations.length === 0)
      return { covenantDescription: "", date: "" };

    const firstSimulation = filteredSimulations[0];
    return {
      covenantDescription:
        firstSimulation.covenantDescription || "Descrição não disponível",
      date:
        new Date(firstSimulation.date).toLocaleDateString("pt-BR") ||
        "Data não disponível",
    };
  };

  const countSuccessfulBanks = (simulations: Simulation[]): number => {
    let successfulCount = 0;

    simulations.forEach((simulation: Simulation) => {
      const { conditions } = simulation;
      if (conditions && conditions.conditions) {
        Object.values(conditions.conditions).forEach((bankData) => {
          if (Array.isArray(bankData)) {
            successfulCount += bankData.filter(
              (data) => data.status === "success"
            ).length;
          }
        });
      }
    });

    return successfulCount;
  };

  const { covenantDescription, date } = getUniqueCovenantAndDate();
  const successfulBanksCount = countSuccessfulBanks(filteredSimulations);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSimulations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalItems = filteredSimulations.length;

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <PageSubTitle size="h3">Detalhes do Lote</PageSubTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {successfulBanksCount > 0 && (
          <Alert
            className={`${styles.alertTitleOportunity} mb-4`}
            variant="light"
          >
            Você tem {successfulBanksCount} Oportunidades de negócios! 🚀
          </Alert>
        )}
        <Row className="mb-4">
          <Col className="d-flex align-items-center">
            <Badge bg="secondary" className="me-2">
              {covenantDescription}
            </Badge>
            <Badge bg="secondary" className="me-2">
              {date}
            </Badge>
          </Col>
          <Col className="d-flex justify-content-end">
            <ButtonPrimary onClick={handleExport} content="Exportar" />
          </Col>
        </Row>
        <Form className="mb-4">
          <Form.Control
            type="text"
            placeholder="Buscar por CPF"
            value={searchCpf}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {currentItems.map((simulation) => (
              <Accordion key={simulation.id}>
                <Accordion.Item eventKey={simulation.id}>
                  <Accordion.Header>
                    <span>{simulation.cpf}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* Exibir erro das conditions, se existir */}
                    {simulation.conditions?.error && (
                      <Alert variant="danger">
                        {simulation.conditions.error}
                      </Alert>
                    )}
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Produto</th>
                          <th>Prazo</th>
                          <th>Parcela</th>
                          <th>Valor Líquido</th>
                          <th>Valor Bruto</th>
                          <th>Taxa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {simulation.conditions?.conditions &&
                          Object.entries(simulation.conditions.conditions).map(
                            ([bankKey, bankData]) =>
                              renderBankData(bankKey, bankData)
                          )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </Modal.Body>
      {toastMessage && <ToastWarning message={toastMessage} />}
    </Modal>
  );
};

export default ViewDataBatchSimulation;