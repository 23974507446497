import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ReturnBatchQuery.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../components/PageSubTitle';
import logo from '../../../../assets/images/acelera_logo.png';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import ReturnList from './ReturnList';
import FilterSearchReturn from '../../../../components/BatchQuery/FIlterSearchReturn';
import ProcessingQueue from '../../../../components/BatchQuery/ProcessingQueue';
import { useLocation } from 'react-router-dom';
import AvailableBalance from '../../../../components/AvailableBalance';

export default function ReturnBatchQuery() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [processedCPFs, setProcessedCPFs] = useState<number>(JSON.parse(localStorage.getItem('processedCpfs') || '0'));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const totalCpfs = JSON.parse(searchParams.get("tC") || '0');

  const subtitleTextClient = "Confira o(s) retorno(s) da sua simulação em lote.";

  const handleBack = (e: React.FormEvent) => {
    window.location.href = '/ConsultaLote';
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedProcessedCPFs = JSON.parse(localStorage.getItem('processedCpfs') || '0');
      setProcessedCPFs(updatedProcessedCPFs);

      if (updatedProcessedCPFs >= totalCpfs) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [totalCpfs]);

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.balanceConatiner} d-flex justify-content-end`}>
          <AvailableBalance />
        </div>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta em Lote</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start'>
              <ProcessingQueue processedCPFs={processedCPFs} totalCPFs={totalCpfs}/>
            </Col>
            <Col className='d-flex justify-content-center'>
             
            </Col>
            <Col className='d-flex justify-content-end'>
              <ButtonPrimary content={<><FontAwesomeIcon icon={faFileExcel} className='me-2'/> <span>Exportar</span></>} />
            </Col>
          </Row>
          <div className='mt-5'>
            <ReturnList/>
          </div>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-1'>
              <ButtonPrimary 
                content={<><FontAwesomeIcon icon={faAdd} className='me-2'/> <span>Nova Simulação</span></>} 
                onClick={handleBack}
              />
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
