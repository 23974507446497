
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import styles from './SelectProductInss.module.scss';
import { motion } from 'framer-motion';
import logo from '../../../../assets/images/acelera_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ProductList from '../../../Query/UnitaryQuery/ProductListSimulation';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  background-color: #153B7F;
  border: 2px solid #153B7F;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.25);
  }
`;

export default function ProductSelectionInssBatch() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const totalSteps = 5;
  const currentStep = 2;
  const subtitleTextClient = "Revise os dados e selecione os produtos.";

  localStorage.removeItem("processedCpfs")

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem('formData') || '{}');
    if (selectedProductId) {
      formData.serviceCode = "010";
      formData.produto = selectedProductId;
      localStorage.setItem('formData', JSON.stringify(formData));
    }
  }, [selectedProductId]);

  const handleProductSelection = (selectedId: string) => {
    setSelectedProductId(selectedId);
    setToastMessage('');

    if (selectedId === 'NORMAL') {
      localStorage.setItem('formData', JSON.stringify({ serviceCode: '010', produto: selectedId }));
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProductId) {
      setToastMessage('Por favor, selecione um produto antes de prosseguir.');
      return;
    }

    window.location.href = '/ConsultaLote/Inss/SelecaoBancos';
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta em Lote</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <ProductList 
            onProductSelect={handleProductSelection}
            selectedProductId={selectedProductId}
           
          />

          <Row className='mt-5'>
            
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
         
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
