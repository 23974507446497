import { useState, ChangeEvent, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SearchField from '../../../components/SearchField';
import Pagination from '../../../components/Pagination';
import { Container, CovenantCard, CheckIcon, CovenantName, CovenantLogo, SelectedCount } from './CovenantComponents';
import logoSiape from '../../../assets/images/logo-siape.png'

const CovenantList = ({ onSelectionChange }: { onSelectionChange: (selectedId: string) => void }) => {
  const [selectedCovenant, setSelectedCovenant] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Dados estáticos para o convênio "SIAPE"
  const covenants = [
    {
      covenantId: '1',
      covenantDescription: 'SIAPE',
      path: logoSiape
    },
  ];

  // Armazenando convênio selecionado no localStorage
  useEffect(() => {
    if (selectedCovenant) {
      const selectedCovenantData = covenants.find(c => c.covenantId === selectedCovenant);
      if (selectedCovenantData) {
        const formData = JSON.parse(localStorage.getItem('formData') || '{}');
        formData.covenantId = selectedCovenantData.covenantId;
        formData.covenantDescription = selectedCovenantData.covenantDescription;
        localStorage.setItem('formData', JSON.stringify(formData));
        onSelectionChange(selectedCovenantData.covenantId);
      }
    }
  }, [selectedCovenant, covenants, onSelectionChange]);

  const handleSelectCovenant = (covenantId: string) => {
    setSelectedCovenant(prevSelected => {
      if (prevSelected === covenantId) {
        localStorage.removeItem('covenantId');
        return null; 
      } else {
        localStorage.setItem('covenantId', covenantId);
        return covenantId;
      }
    });
  };

  // Filtrar convênios com base no termo de pesquisa (mesmo com apenas um convênio, para manter a funcionalidade)
  const filteredCovenants = covenants.filter(covenant =>
    covenant.covenantDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Variáveis para paginação (mantido para o caso de ter mais convênios no futuro)
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredCovenants.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredCovenants.length);
  const currentItems = filteredCovenants.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const selectedConvenantName = covenants.find(convenant => convenant.covenantId === selectedCovenant)?.covenantDescription;

  return (
    <>
      <SearchField
        search={searchTerm}
        onSearchChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchTerm(e.target.value)
          setCurrentPage(1);
        }}
        placeholder="Pesquisar convênio..."
      />

      <Container>
        <Row className="d-flex justify-content-center">
          {currentItems.map(covenant => (
            <CovenantCard
              sm={12} md={3} key={covenant.covenantId}
              onClick={() => handleSelectCovenant(covenant.covenantId)}
              className={`selectedCovenant === covenant.covenantId ? 'selected' : '' me-5 mb-5 mt-2`}
              aria-label={`Select ${covenant.covenantDescription}`}
            >
              <CheckIcon
                icon={faCheck}
                className={selectedCovenant === covenant.covenantId ? 'selected' : ''}
                aria-hidden="true"
              />
              {covenant.path && (
                <CovenantLogo
                  src={covenant.path}
                  alt={covenant.covenantDescription}
                />
              )}
              <CovenantName>{covenant.covenantDescription}</CovenantName>
            </CovenantCard>
          ))}
        </Row>
      </Container>

      {selectedCovenant && (
        <SelectedCount>
          <span>Convênio selecionado: {selectedConvenantName}</span>
        </SelectedCount>
      )}

      {totalPages > 1 && (
        <Pagination
          totalItems={filteredCovenants.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default CovenantList;
