import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ReturnBatchQuery.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../components/PageSubTitle';
import logo from '../../../../assets/images/acelera_logo.png';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ReturnList from './ReturnList/index';
import ProcessingQueue from '../../../../components/BatchQuery/ProcessingQueue';
import DropDownButtonPrimary from '../../../../components/Buttons/DropdownButtonPrimary';
import FilterSearchReturn from '../../../../components/BatchQuery/FIlterSearchReturn';
import { useLocation } from 'react-router-dom';

interface CreditConditionsData {
  installmentQuantity?: string;
  installmentAmount?: string;
  netAmount?: string;
  grossAmount?: string;
  clientRate?: string;
  product?: string;
}

interface SimulationConditions {
  bmg?: { status: string; error?: string; data?: CreditConditionsData };
  master?: { status: string; error?: string; data?: CreditConditionsData };
  c6?: { status: string; error?: string; data?: CreditConditionsData };
  pan?: { status: string; error?: string; data?: CreditConditionsData };
}

interface SimulationData {
  cpf: string;
  covenantId: string;
  covenantDescription: string;
  productId: string;
  productDescription: string;
  simulationId: string;
  error?: string;
  conditions?: SimulationConditions;
}

const formatCurrency = (value: number | null) => {
  return value !== null ? `R$ ${value.toFixed(2).replace('.', ',')}` : '-';
};

const isSimulationConditions = (conditions?: SimulationConditions): conditions is SimulationConditions => {
  return !!conditions;
};

export default function ReturnBatchQueryCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [processedCPFs, setProcessedCPFs] = useState<number>(0);
  const [cpfFilter, setCpfFilter] = useState<string>('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lote = searchParams.get("lote");
  const totalCpfs = JSON.parse(searchParams.get("tC") || '0');
  const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
  const covenantDescriptionLocalStorage = formDataLocalStorage.covenantDescription;

  const dropdownItems = [
    { label: covenantDescriptionLocalStorage, link: '/ConsultaLote/Convenios/EnvioArquivo' },
    { label: 'Outro Convênio', link: '/ConsultaLote/Convenios' }
  ];
  const subtitleTextClient = "Confira o(s) retorno(s) da sua simulação em lote.";

  const handleSimulation = async () => {
    try {
      const res = await fetch(`https://acelereai.gfttech.com.br/api/v1/proposal/simulation/find-by-batch?batchId=${lote}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (res.ok) {
        const data = await res.json();
  
        const formattedData = data.flatMap((item: any) => {
          const banks = Object.keys(item.conditions.conditions);
  
          return banks.flatMap((bank) => {
            const bankCondition = item.conditions.conditions[bank];
  
            // Filtra apenas os status 'success'
            const successItems = bankCondition.filter((condition: any) => condition.status === "success");
  
            // Se houver itens com status 'success'
            if (successItems.length > 0) {
              return successItems.map((condition: any) => ({
                cpf: item.cpf,
                covenantDescription: item.covenantDescription,
                bank: bank.toUpperCase(), 
                product: condition.product,
                installmentQuantity: condition.installmentQuantity,
                installmentAmount: condition.installmentAmount,
                netAmount: condition.netAmount,
                grossAmount: condition.grossAmount,
                clientRate: condition.clientRate,
                availableMargin: item.conditions.margin.availableMargin || null,
                availableMarginCard: item.conditions.margin.availableMarginCard || null,
                availableCardLimit: item.conditions.margin.availableCardLimit || null,
              }));
            }
  
            return [];
          });
        });
  
        setSimulations(formattedData);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };  
  
  useEffect(() => {
    handleSimulation();
  }, [])

  const handleExportButton = async () => {
    try{
      const requestBody = {
        fileName: "Retorno_Simulacao_Convenios",
        pageName: "Listagem Simulações",
        pageHeader: [
          "CPF",
          "Convênio",
          "Banco",
          "Produto",
          "Parcelas",
          "Valor Parcelas",
          "Valor Líquido",
          "Valor Bruto",
          "Taxa",
          "Margem",
          "Margem Cartão",
          "Limite Cartão",
        ],
        data: simulations
      }

      const res = await fetch('http://192.168.254.88:3333/api/portal/createFile/excel',{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (res.ok) {
        const blob = await res.blob(); 
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Retorno_Simulacao_Convenio.xlsx"); 
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
          setToastMessage('Arquivo criado com sucesso!')
        } else {
          console.warn("link não foi adicionado corretamente");
        }
      } else {
        setToastMessage("Erro ao criar arquivo");
      }
    } catch(error){
      console.log(error);
    }
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} alt="Logo_Aceleraai" />
      </Container>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta em Lote</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start'>
              <ProcessingQueue processedCPFs={processedCPFs} totalCPFs={totalCpfs} />
            </Col>
            <Col className='d-flex justify-content-center'>
              <FilterSearchReturn cpfFilter={cpfFilter} setCpfFilter={setCpfFilter} />
            </Col>
            <Col className='d-flex justify-content-end'>
              <ButtonPrimary content="Exportar" onClick={handleExportButton} />
            </Col>
          </Row>
          <div className='mt-5'>
            <ReturnList cpfFilter={cpfFilter} setProcessedCPFs={setProcessedCPFs} totalCpfs={totalCpfs} />
          </div>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-end mt-1'>
              <DropDownButtonPrimary title="Nova Simulação" items={dropdownItems} />
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
