import { useState, useEffect } from 'react';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import logo from '../../../../assets/images/Logo_Quero_Mais_Crédito.png';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import bmgLogo from '../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../assets/images/banco c6.png';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import masterLogo from '../../../../assets/images/banco master.png';
import DropDownButtonPrimary from '../../../../components/Buttons/DropdownButtonPrimary';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import siapeLogo from '../../../../assets/images/logo-siape.png'

export default function ClientDataCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [result, setResult] = useState<any>(null);
  const [pathCovenant, setPathCovenant] = useState('')
  const [opportunityCount, setOpportunityCount] = useState<number>(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const simulationId = searchParams.get('iS');

  const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
  const covenantDescriptionLocalStorage = formDataLocalStorage.covenantDescription;
  const dropdownItems = [
    { label: covenantDescriptionLocalStorage, link: '/ConsultaUnitaria/Convenio/DadosCliente' },
    { label: 'Outro Convênio', link: '/ConsultaUnitaria/Convenios' }
  ];

  const subtitleTextClient = "Confira o retorno da simulação";

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Container className="d-flex justify-content-center mb-5">
        <img src={logo} style={{maxWidth: 220}} alt="Logo_Aceleraai" />
      </Container>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-2`}>
          <PageTitle size="h2">Retorno da simulação</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-1`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row>
            <Col className="d-flex justify-content-start">
              <Badge bg="success" className={`${styles.oportunityBage} me-2`}>
                <FontAwesomeIcon icon={faSearchDollar} /> Você tem uma oportunidade de negócio!
              </Badge>
            </Col>
            <Col className="d-flex justify-content-end">
              <Badge bg="secondary" className={`${styles.badgeCardMargin} me-2`}>Margem Cartão: R$ 362,50</Badge>
            </Col>
          </Row>

          <Row className={`${styles.resultSimulation} mt-5`}>          
            <Col xs={12} sm={6} md={4} className="mb-3">
              <Card className={styles.cardContainer}>
                <Card.Header className={styles.cardHeader}>
                  Cartão RMC/RCC
                </Card.Header>
                <Card.Body className={styles.cardBody}>
                  <div className={styles.logoContainer}>
                    <img className={styles.covenantLogo} src={siapeLogo}/>
                  </div>
                  <div className={styles.netAmount}>
                    R$ 8254,12
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-end'>
              <DropDownButtonPrimary title="Nova Simulação" items={dropdownItems} />
            </Col>
          </Row>
        </Container>
      </motion.div>
      <ToastValidationSimulation message={toastMessage} />
    </>
  );
}
